/* eslint-disable */
const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://vu3wqguh06.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://w5ylt3e3kbeopfcdh76stwic24.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:04c06fb7-a446-4192-8f34-47e02ace706d",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Ns5hQitRZ",
    "aws_user_pools_web_client_id": "79j7rmf4d5k2624aaostf2i8b0",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "dev.baucamera.cloud",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://dev.baucamera.cloud.s3-website.eu-central-1.amazonaws.com",
    "aws_user_files_s3_bucket": "baucameracloud8e9d90a2c10048249c4ad58ddddf50f7124209-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
